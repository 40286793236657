import { general } from "config/config"
import useUser from "hooks/useUser"
import { NextWebVitalsMetric } from "next/app"
import { useRouter } from "next/router"
import { useEffect } from "react"

import { IPage } from "data/contentData/api/content"

export function reportWebVitals({ name, label, value }: NextWebVitalsMetric) {
    window.dataLayer.push({
        event: "web-vitals",
        event_action: name,
        event_category:
            label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
        event_label: label,
        event_value: Math.round(name === "CLS" ? value * 1000 : value)
    })
}

/**
 * Send custom variables to GTM
 * @param page page data model
 */
export function useGtmCustomVariables(page?: IPage) {
    const { asPath } = useRouter()
    const { user } = useUser()

    const articleGuid = page?.contentGuid ?? ""
    const templateType = page?.types?.join(".") ?? ""
    const userGuid = user?.guid ?? ""
    const parentGuid = user?.parentId ?? ""

    useEffect(() => {
        window.dataLayer.push({
            articleGuid,
            templateType,
            userGuid,
            parentGuid
        })
    }, [articleGuid, parentGuid, asPath, templateType, userGuid])

    useEffect(() => {
        if (general.enableInternalTracking) {
            fetch("/api/usage/record")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [asPath])
}
