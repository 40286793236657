import { CacheProvider, EmotionCache } from "@emotion/react"
import AuthWrapper from "components/Auth"
import { Icon } from "components/Icon"
import createEmotionCache from "createEmotionCache"
import { t } from "locales"
import { AppProps } from "next/app"
import Head from "next/head"
import { SnackbarProvider, useSnackbar } from "notistack"
import { PropsWithChildren, ReactNode, useEffect, useState } from "react"
import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"

import Button from "@mui/material/Button"
import CssBaseline from "@mui/material/CssBaseline"
import {
    ThemeProvider as MuiThemeProvider,
    Theme,
    createTheme,
    responsiveFontSizes
} from "@mui/material/styles"

import { signIn, useAllowIpLogin } from "lib/next-auth"
import defaultTheme from "lib/theme"

import "/styles/main.css"
import { HttpError } from "lib/http/http"

export { reportWebVitals } from "lib/gtag"

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

interface EmiliaAppProps extends AppProps {
    emotionCache?: EmotionCache
}

export default function App(props: EmiliaAppProps) {
    const {
        Component,
        emotionCache = clientSideEmotionCache,
        pageProps
    } = props

    useAllowIpLogin()
    useEffect(() => {
        const jssStyles = document.querySelector("#jss-server-side")
        if (jssStyles) {
            jssStyles.parentElement?.removeChild(jssStyles)
        }
    }, [])

    const theme = responsiveFontSizes(createTheme(defaultTheme as Theme))
    //   theme.typography.h4 = {
    //     fontFamily: theme.typography.h4.fontFamily,
    //     lineHeight: theme.typography.h4.lineHeight,
    //     ...themeObject.typography.h4,
    //   }

    return (
        <CacheProvider value={emotionCache}>
            <Head>
                {/* https://github.com/vercel/next.js/blob/master/errors/no-document-title.md */}
                <title>{t["site-name"]}</title>
                {/* https://github.com/vercel/next.js/blob/master/errors/no-document-viewport-meta.md */}
                <meta
                    content="minimum-scale=1, initial-scale=1, width=device-width"
                    name="viewport"
                />
            </Head>
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <SnackbarWrapper>
                    <QueryClientWrapper>
                        <AuthWrapper auth={Component.auth}>
                            <ReactQueryDevtools />
                            <Component {...pageProps} />
                        </AuthWrapper>
                    </QueryClientWrapper>
                </SnackbarWrapper>
            </MuiThemeProvider>
        </CacheProvider>
    )
}

function SnackbarWrapper({ children }: { children: ReactNode }) {
    return (
        <SnackbarProvider
            anchorOrigin={{
                horizontal: "center",
                vertical: "bottom"
            }}
            className="no-print"
            iconVariant={{
                error: (
                    <Icon
                        name="error"
                        sx={{ mr: 1 }}
                    />
                ),
                success: (
                    <Icon
                        name="checkCircle"
                        sx={{ mr: 1 }}
                    />
                )
            }}>
            {children}
        </SnackbarProvider>
    )
}

function SignInAction({ children }: PropsWithChildren<ReactNode>) {
    return (
        <Button
            color="inherit"
            variant="outlined"
            onClick={() => signIn()}>
            {children}
        </Button>
    )
}

function QueryClientWrapper({ children }: { children: ReactNode }) {
    const { enqueueSnackbar } = useSnackbar()
    const [queryClient] = useState(
        () =>
            // Good staletime/cachetime explanation here: https://github.com/tannerlinsley/react-query/discussions/1685
            new QueryClient({
                defaultOptions: {
                    queries: {
                        // ✅ globally default to 20 seconds
                        staleTime: 1000 * 20,
                        refetchOnWindowFocus: false,
                        onError: error => {
                            if (error instanceof HttpError) {
                                switch (error.response.status) {
                                    case 400:
                                        enqueueSnackbar(t["general-error"], {
                                            variant: "error"
                                            // eslint-disable-next-line react/display-name
                                        })
                                        break
                                    case 403:
                                        enqueueSnackbar(
                                            t["unauthenticated-request"],
                                            {
                                                variant: "error",
                                                // eslint-disable-next-line react/display-name
                                                action: () => (
                                                    <SignInAction>
                                                        {t.login}
                                                    </SignInAction>
                                                )
                                            }
                                        )
                                        break
                                    case 404:
                                        break
                                    case 500:
                                        enqueueSnackbar(t["general-error"], {
                                            variant: "error"
                                            // eslint-disable-next-line react/display-name
                                        })
                                        break
                                    case 401:
                                        enqueueSnackbar(
                                            t["unauthorized-request"],
                                            {
                                                variant: "error",
                                                // eslint-disable-next-line react/display-name
                                                action: () => (
                                                    <SignInAction>
                                                        {t["login-again"]}
                                                    </SignInAction>
                                                )
                                            }
                                        )
                                        break
                                    default:
                                        break
                                }
                            }
                        }
                    }
                }
            })
    )
    return (
        <QueryClientProvider client={queryClient}>
            {children}
        </QueryClientProvider>
    )
}
