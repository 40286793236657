import { rewrites } from "config/config"
import { Session } from "next-auth"
import { useQuery } from "react-query"
import { OptionsType } from "types/shared.types"

import { fetchSession, signIn, signOut } from "lib/next-auth"

interface IUseUser extends Partial<Session> {
    authenticated: boolean
    isLoading: boolean
    isFetching: boolean
}

export default function useUser(): IUseUser {
    const {
        data: session,
        isLoading,
        isFetching,
        refetch
    } = useQuery<Session | null, Error>(
        ["session"],
        fetchSession,
        sessionQueryConfig
    )

    const authenticated = isAuthenticated(isLoading, session)

    if (shouldRefetchSession(isFetching, session)) {
        refetch()
    }

    if (session?.error) {
        session.user = undefined
    }

    return {
        ...(session ?? {}),
        authenticated,
        isLoading,
        isFetching
    }
}

function shouldRefetchSession(isFetching: boolean, session?: Session | null) {
    return (
        !isFetching &&
        session &&
        session.accessToken &&
        session.accessTokenExpires < Date.now()
    )
}

function isAuthenticated(loading: boolean, session?: Session | null) {
    return Boolean(
        !loading &&
            session &&
            session.user &&
            session.accessToken &&
            session.accessTokenExpires > Date.now() &&
            !session.error
    )
}

let handled = false
export const sessionQueryConfig: OptionsType<Session | null> = {
    refetchOnWindowFocus: true,
    onSettled(session?: Session | null) {
        if (handled || !session?.error) {
            return
        }
        handled = true
        switch (session.error) {
            case "concurrency": {
                const path = rewrites["/concurrent-logins"]
                const userConcurrency = session.user?.concurrency
                // Redirect to page that explains the reason
                signOut({ callbackUrl: `${path}?allowed=${userConcurrency}` })
                break
            }
            case "logged-out":
                // The user has been logged out through a front-channel logout, log them out of the app as well
                signOut({ redirect: false })
                break
            case "restored": // The server has been restarted, we try to log the user in, silently, if possible.
                signIn()
                break
            case "refresh-access-token": // The refresh token did not work, try logging the user in, silently, if possible.
                signIn()
                break
            case "jwt":
                signOut({ callbackUrl: "/error?code=auth" })
            default:
                break
        }
    }
}
